<template>
  <div>
    <div class="row">
      <div class="col">
        <div style="position: relative">
          <div class="d-flex">
            <toggle-button v-model="showWeekends"
                           :disabled="disabled"
                           class="my-auto ms-auto ms-auto"
                           color="#03006b"
                           :sync="true"
                           :width=100
                           :height=25
                           :labels="{checked: 'Weekends', unchecked: 'Weekends'}" />
          </div>
          <!-- Earlier -->
          <div class="small"
               :class="!expandedUp && !disabled ? 'text-primary cursor-pointer' : 'text-white'"
               style="margin-bottom: -10px"
               @click="expandTimes('up')">
            <i class="far fa-chevron-up mx-2" v-tooltip:bottom="'Earlier'"/>
          </div>
          <div class="vws-rule-custom" style='user-select: none;'>
            <div class="vws-rule-row">
              <div class="vws-table-rule">
                <div class="vws-table-rule-heading">
                  <div class="vws-time-list vws-rule-time-time vws-time-rule opacity-0">Time</div>
                  <div class="text-center fw-bold" v-for="day in dayTable">{{day.short}}</div>
                </div>
                <div class="vws-table-rule-body" id='schedule' ref="draggableArea" @mousedown="startDrag" @mousemove="doDrag">

                  <div ref="ruleTime" class="vws-rule-time" v-for="t in timeArray" :key="t">
                    <div
                        ref="ruleTimeTime"
                        class="vws-time-list vws-rule-time-time vws-time-rule"
                        :data-val="t"
                    >
                      {{ t }}
                    </div>
                    <div
                        v-for="(day, daynum) in dayTable"
                        :key="daynum"
                        ref="ruleTimeItem"
                        class="vws-time-list vws-rule-time-item"
                        :class="{
                    'active': (timetable[day.full] && timetable[day.full].find(el => el === t)),
                    'disabled': disabled
                  }"
                        @click="toggleSlot(daynum, t)"
                    >
                      <span><i class="fas fa-check-circle text-white" /></span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Later -->
          <div class="small"
               :class="!expandedDown && !disabled ? 'text-primary cursor-pointer' : 'text-white'"
               @click="expandTimes('down')">
            <i class="far fa-chevron-down mx-2" v-tooltip:bottom="'Later'"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isPresetSelection">
      <div class="row">
        <div class="col text-end">
          <button class="btn btn-light btn-sm ms-auto ms-auto me-2 me-2"
                  type="button"
                  @click.prevent="savePreset">
            <i class="far fa-cloud-upload me-1 me-1" />
            Save as Preset
          </button>
        </div>
      </div>

      <SaveWorkingHoursPresetModal v-if="showSavePreset"
                                   :preset="searchableFormat"
                                   @closed="showSavePreset=false"
                                   @saved="showSavePreset=false"
      />
    </div>

  </div>
</template>

<script>
import SaveWorkingHoursPresetModal from "./SaveWorkingHoursPresetModal";
export default {
  components: {SaveWorkingHoursPresetModal},
  name: "WeekScheduleSelect",
  props: {
    value: {},
    isPresetSelection: false,
    interval: {
      type: Number,
      default: 30
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      timetable: {
        "monday": [],
        "tuesday": [],
        "wednesday": [],
        "thursday": [],
        "friday": [],
        "saturday": [],
        "sunday": []
      },
      dayTable: this.getDefaultDayTable(),
      timeArray: [],
      fullTimeArray: [],
      dragValue: false,
      dragging: false,
      x: 0,
      y: 0,
      startX: 0,
      startY: 0,
      maxX: 0,
      maxY: 0,
      minX: 1000000,
      minY: 1000000,
      showWeekends: false,
      expandedUp: false,
      expandedDown: false,
      timeTrimStart: 16,
      timeTrimEnd: 36,
      searchableFormat: {},
      dummyTimetableLoopback: {},
      showSavePreset: false
    }
  },
  methods: {
    savePreset() {
      this.showSavePreset = true;
      $("#saveWorkingHoursPresetModal").modal("show");
    },
    startDrag(event) {
      this.dragging = true;
      this.x = this.y = 0;
      this.startX = this.startY = this.maxY = this.maxX  = 0;
      this.minY = this.minX = 1000000
      let item = {
        height: this.$refs.ruleTimeItem[0].clientHeight,
        width: this.$refs.ruleTimeItem[0].clientWidth,
      }
      let time = {
        height: this.$refs.ruleTimeTime[0].clientHeight,
        width: this.$refs.ruleTimeTime[0].clientWidth,
      }
      let week = {
        height: 0,
        width: 0
      }
      this.data = {time, item, week}
      let firstItemX = this.data.time.width + this.data.week.width;
      let dayIndex = 6
      for (let i = 0; i < 8; i++) {
        let width = firstItemX + i * (this.data.item.width)
        if (width > event.layerX && width  < event.layerX  + this.data.item.width) {
          dayIndex = i -1
        }
      }
      let timeData = -1
      let j = 1;
      this.$refs.ruleTime.map((one, timeIndex) => {
        if (!one.className.includes('hides')) {
          let height = 35 + j * (this.data.item.height + 3)
          if (height > event.layerY && height  < event.layerY + this.data.item.height) {
            timeData = timeIndex
          }
          j++
        }
      })

      if(timeData == -1){
        this.dragValue = false;
      }else if (dayIndex == -1) {
        this.dragValue = false;
      } else {
        this.dragValue = this.timetable[this.dayTable[dayIndex].full].indexOf(this.timeArray[timeData]) === -1
      }
    },
    stopDrag() {
      this.dragging = false;
    },
    doDrag(event) {
      if (this.dragging ) {
        this.x = event.layerX;
        this.y = event.layerY;
        if (event.layerY > this.maxY) {
          this.maxY = event.layerY
        }
        if (event.layerX > this.maxX) {
          this.maxX = event.layerX
        }
        if (event.layerY < this.minY) {
          this.minY = event.layerY
        }
        if (event.layerX < this.minX) {
          this.minX = event.layerX
        }
        if (!this.startX) {
          this.startX = event.layerX;
          this.startY = event.layerY;
        }
        let firstItemX = this.data.time.width + this.data.week.width
        for (let i = 0; i < 8; i++) {
          let width = firstItemX + i * (this.data.item.width)
          let isSetItem = false;
          if (this.startX < this.x) {
            if (width > this.startX && width  < event.layerX  + this.data.item.width ) {
              isSetItem = true;
            }
          } else {
            if (width < this.startX + this.data.item.width && width  > event.layerX   ) {
              isSetItem = true;
            }
          }

          let j = 1;
          this.$refs.ruleTime.map((one, timeIndex) => {
            if (!one.className.includes('hides')) {
              let height = 35 + j * (this.data.item.height + 3)
              if (this.startY < this.y) {
                if (height > this.startY && height  < this.y + this.data.item.height) {
                  isSetItem && this.setSlot(i - 1, this.timeArray[timeIndex], this.dragValue)
                }
                if ( height > this.startY && height  < this.maxY + this.data.item.height ) {
                  if (this.startX < this.x) {
                    if (this.x < this.maxX && width + this.data.item.width < this.maxX && width > this.x - this.data.item.width && i < 6) {
                      this.setSlot(i + 1, this.timeArray[timeIndex], !this.dragValue)
                    }
                  } else {
                    if (this.x > this.minX && width  > this.minX - this.data.item.width && width < this.x - this.data.item.width && i < 6) {
                      this.setSlot(i , this.timeArray[timeIndex], !this.dragValue)
                    }
                  }
                }
                if (this.y < this.maxY) {
                  if (height > this.y + this.data.item.height && height  < this.maxY + this.data.item.height) {
                    isSetItem && this.setSlot(i - 1, this.timeArray[timeIndex], !this.dragValue)
                  }
                }
              } else {
                if (height - this.data.item.height  < this.startY    && height > this.y ) {
                  isSetItem && this.setSlot(i - 1, this.timeArray[timeIndex], this.dragValue)

                }

                if ( height - this.data.item.height  < this.startY && height  > this.minY ) {
                  if (this.startX < this.x) {
                    if (this.x < this.maxX && width + this.data.item.width < this.maxX && width > this.x  - this.data.item.width ) {
                      this.setSlot(i + 1, this.timeArray[timeIndex], !this.dragValue)
                    }
                  } else {
                    if (this.x > this.minX && width > this.minX - this.data.item.width  && width < this.x  - this.data.item.width ) {
                      this.setSlot(i , this.timeArray[timeIndex], !this.dragValue)
                    }
                  }
                }
                if (this.y > this.minY) {
                  if (height   > this.minY    && height < this.y) {
                    isSetItem && this.setSlot(i - 1, this.timeArray[timeIndex], !this.dragValue)
                  }
                }
              }
              if (this.startX < this.x) {
                if (this.x < this.maxX) {
                }
              }
              j++
            }
          })
        }
      }
    },
    setSlot (dayIndex, time, value) {
      if (!this.disabled) {
        if (dayIndex < 0) { return }

        const day = this.dayTable[dayIndex].full;

        let existingSlotIndex = this.timetable[day].findIndex(el => el == time);
        if (value) {
          if (existingSlotIndex === -1) {
            this.timetable[day].push(time);
          }
        } else {
          if (existingSlotIndex !== -1) {
            this.timetable[day].splice(existingSlotIndex, 1);
          }
        }
        this.emitUpdate();
      }
    },
    toggleSlot (dayIndex, time) {
      if (!this.disabled) {
        const day = this.dayTable[dayIndex].full;
        let existingSlotIndex = this.timetable[day].findIndex(el => el === time);
        if (existingSlotIndex !== -1) {
          this.timetable[day].splice(existingSlotIndex, 1);
        } else {
          this.timetable[day].push(time);
        }
        this.emitUpdate();
      }
    },
    sortTimetableData() {
      this.dayTable.forEach((day) => {
        this.timetable[day.full] = this.timetable[day.full].sort((first, second) => {
          if (parseInt(first.replace(":", "")) <= parseInt(second.replace(":", ""))) {
            return -1
          } else {
            return 1;
          }
        })
      })
    },
    seedTimes() {
      if (this.timeArray.length === 0) {
        let times = []; // time array
        let tt = 0; // start time
        //loop to increment the time and push results in array
        for (var i=0;tt<24*60; i++) {
          let hh = Math.floor(tt/60); // getting hours of day in 0-24 format
          let mm = (tt%60); // getting minutes of the hour in 0-55 format
          times[i] = ("0" + (hh)).slice(-2) + ":" + ("0" + (mm)).slice(-2); // pushing data in array
          tt = tt + this.interval;
        }
        this.fullTimeArray = times;
        this.timeArray = this.fullTimeArray.slice(this.timeTrimStart, this.timeTrimEnd);
      }
    },
    expandTimes(direction) {
      if (direction === 'up') {
        if (!this.expandedUp) {
          this.timeArray = [...this.fullTimeArray.slice(0, this.timeTrimStart), ...this.timeArray];
          this.expandedUp = true;
        }
      } else if (direction === 'down') {
        if (!this.expandedDown) {
          this.timeArray = [...this.timeArray, ...this.fullTimeArray.slice(this.timeTrimEnd, this.fullTimeArray.length)];
          this.expandedDown = true;
        }
      }
    },
    getDefaultDayTable() {
      // We should check if the v-model has any weekend data instead
      if (!this.value || (!this.value['saturday'] || this.value['saturday'].length === 0) && (!this.value['sunday'] || this.value['sunday'].length === 0)) {
        return this.getDayTableWithoutWeekends();
      } else {
        return this.getDayTableWithWeekends();
      }
    },
    getDefaultSearchableFormat() {
      return {
        "monday": [],
        "tuesday": [],
        "wednesday": [],
        "thursday": [],
        "friday": [],
        "saturday": [],
        "sunday": []
      }
    },
    getDayTableWithoutWeekends() {
      return [
        { full: "monday", short: "Mon" },
        { full: "tuesday", short: "Tue" },
        { full: "wednesday", short: "Wed" },
        { full: "thursday", short: "Thu" },
        { full: "friday", short: "Fri" }
      ]
    },
    getDayTableWithWeekends() {
      return [
        { full: "monday", short: "Mon" },
        { full: "tuesday", short: "Tue" },
        { full: "wednesday", short: "Wed" },
        { full: "thursday", short: "Thu" },
        { full: "friday", short: "Fri" },
        { full: "saturday", short: "Sat" },
        { full: "sunday", short: "Sun" },
      ]
    },
    emitUpdate() {
      this.sortTimetableData();
      this.generateSearchableFormat();
      this.generateTimetableLoopback();
      this.$emit('input', this.searchableFormat);
    },
    incrementTimeStringHelper(hours, minutes) {
      let closeHours = hours;
      let closeMinutes = minutes;

      if (closeMinutes < (60 - this.interval)) {
        closeMinutes = (parseInt(closeMinutes) + this.interval).toString().padStart(2, '0');
      } else {
        closeMinutes = "00"
        closeHours = (parseInt(hours) + 1).toString().padStart(2, '0');

        if (closeHours === "24") {
          closeHours = "23";
          closeMinutes = "59";
        }
      }

      return closeHours + closeMinutes;
    },
    generateDefaultObjToAdd(hours, minutes) {
      return {
        open: hours + minutes,
        close: this.incrementTimeStringHelper(hours, minutes)
      }
    },
    generateSearchableFormat() {
      ['monday','tuesday','wednesday','thursday','friday', 'saturday', 'sunday'].forEach((day) => {
        this.searchableFormat[day] = [];
        let objToAdd = null;
        for (let i =0; i < this.timetable[day].length; i++) {
          const timetableItem = this.timetable[day][i].replace(":", "");
          const openHours = timetableItem.substring(0, 2);
          const openMinutes = timetableItem.substring(2, 4);

          if (objToAdd === null) {
            objToAdd = this.generateDefaultObjToAdd(openHours, openMinutes);
          } else {
            if (timetableItem === objToAdd.close) {
              objToAdd.close = this.incrementTimeStringHelper(openHours, openMinutes);
            } else {
              this.searchableFormat[day].push(objToAdd);
              objToAdd = this.generateDefaultObjToAdd(openHours, openMinutes);
            }
          }
        }

        if (objToAdd) {
          if (!objToAdd.close) {
            objToAdd.close = this.incrementTimeStringHelper(objToAdd.open.substring(0, 2), objToAdd.open.substring(2, 4));
          }
          this.searchableFormat[day].push(objToAdd);
        }
      });

    },
    generateTimetableLoopback() {
      this.timetable = {};

      ['monday','tuesday','wednesday','thursday','friday', 'saturday', 'sunday'].forEach((day) => {
        this.timetable[day] = [];
        this.searchableFormat[day].forEach((rangeArray) => {
          let rollingVal = rangeArray.open;

          while (parseInt(rollingVal) < parseInt(rangeArray.close)) {
            this.timetable[day].push(rollingVal.substr(0, 2) + ":" + rollingVal.substr(2, 4));
            rollingVal = this.incrementTimeStringHelper(rollingVal.substr(0, 2), rollingVal.substr(2, 4))
          }
        });
      });
    }
  },
  mounted () {
    if (this.value && Object.keys(this.value).length !== 0) {
      if (typeof this.value === "string") {
        this.searchableFormat = JSON.parse(this.value);
      } else {
        this.searchableFormat = this.value;
      }
    } else {
      this.searchableFormat = this.getDefaultSearchableFormat();
    }

    if (this.disabled || ((!this.searchableFormat['saturday'] || this.searchableFormat['saturday'].length === 0) && (!this.searchableFormat['sunday'] || this.searchableFormat['sunday'].length === 0))){
      this.showWeekends = false;
    } else {
      this.showWeekends = true;
    }

    this.seedTimes();
    this.generateTimetableLoopback();

    window.addEventListener('mouseup', this.stopDrag);
  },
  watch: {
    value: {
      handler: function(val) {
        if (val) {
          if (typeof val === "string") {
            this.searchableFormat = JSON.parse(val);
          } else {
            this.searchableFormat = val;
          }
        } else {
          this.searchableFormat = this.getDefaultSearchableFormat();
        }
        this.generateTimetableLoopback();
      },
      deep: true
    },
    showWeekends(val) {
      if (val) {
        this.dayTable = this.getDayTableWithWeekends();
      } else {
        this.dayTable = this.getDayTableWithoutWeekends();
        this.searchableFormat["saturday"] = [];
        this.searchableFormat["sunday"] = [];
        this.emitUpdate();
      }
    },
    disabled(newVal) {
      if (newVal) {
        this.showWeekends = false;
        this.searchableFormat = this.getDefaultSearchableFormat();
        this.emitUpdate();
      }
    }
  },
}
</script>

<style scoped>
.vws-table-rule {
  border-collapse: separate;
}
.vws-table-rule-heading, .vws-rule-time {
  display: flex;
  flex-wrap: wrap;
}
.vws-table-rule-heading div, .vws-rule-time .vws-time-list {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  margin: 1px;
  padding: 3px;
  font-size: 9px;
}

.vws-time-rule {
  width: 100px;
  display: block ruby;
}
.vws-rule-time-item{
  cursor: pointer;
  background: #f7f7f7;
  border-radius:5px;
}
.vws-rule-time-item.disabled{
  cursor: not-allowed;
}

.vws-rule-time-item.active{
  background: #03006b;
}

.vws-rule-time-item:hover,
.vws-rule-time-item.active:hover{
  background: #6574cd;
}

.vws-rule-time-item.disabled:hover{
  background: #f7f7f7 !important;
}

.vws-rule-time-item span{
  display: none;
}

.vws-rule-time-item .parent {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 13px;
  font-weight: 800;
  height: 4px;
}

.vws-rule-time-item.active span {
  display: block;
  text-align: center;
  color: var(--light);
}
</style>
<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" id="saveWorkingHoursPresetModal">
      <div class="modal-dialog modal modal-dialog-centered" role="document">
        <div class="modal-content bg-white">
          <div class="modal-body">
            <WorkingHoursForm
                :preset="preset"
                @saved="saved"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WorkingHoursForm from "./WorkingHoursForm";
export default {
  components: {WorkingHoursForm},
  props: ["preset"],
  methods: {
    saved() {
      this.$emit("saved");
      $("#saveWorkingHoursPresetModal").modal("hide");
    },
  },
  mounted() {
    $("#saveWorkingHoursPresetModal").modal("show");

    $("#saveWorkingHoursPresetModal").on("hide.bs.modal", (e) => {
      this.$emit("closed");
    });
  },
};
</script>